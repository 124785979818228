import React from "react";

const Anchor = (props) => {
  const { className = "", href, children } = props;
  return (
    <a 
      className={className}
      href={href}
      target={href.indexOf("http") === -1 ? "_self" : "_blank"}
      rel={href.indexOf("http") === -1 ? "" : "noreferrer"}
    >
      {children}
    </a>
  )
}

export default Anchor;