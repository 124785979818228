import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import Anchor from "../../atoms/anchor/anchor";

import "./supportTemplate.scss";

const SupportElement = (props) => {
  const { description, href, image, textTitle, main = false} = props;

  return (
    <div
      className={`t-support__items__item ${
        main ? "t-support__items__item--main" : ""}`
      }
    >
      <Anchor
        className={`t-support__items__item__image ${
          main ? "t-support__items__item--main__image" : ""}`
        }
        href={href}
      >
        <img
          src={image.file.url}
          alt={textTitle}
        />
      </Anchor>
      <div
        className={`t-support__items__item__text ${
          main ? "t-support__items__item--main__text" : ""}`
        }
      >
        <Anchor
          className={`t-support__items__item__text__title ${
            main ? "t-support__items__item--main__text__title" : ""}`
          }
          href={href}
        >
          {textTitle}
        </Anchor>
        {description &&
          <p className="t-support__items__item__text__desc">
            {JSON.parse(description.raw).content[0].content[0].value}
          </p>
        }
        <Anchor
          className={`icon-chevron-right t-support__items__item__text__arrow ${
            main ? "t-support__items__item--main__text__arrow" : ""}`
          }
          href={href}
        />
      </div>
    </div>
  )
}

const SupportTemplate = () => {
  const data = useStaticQuery(graphql`
		query QUERY_SUPPORT {
			allContentfulPaginaSoporte {
        nodes {
          pageTitle
          elements {
            description {
              raw
            }
            href
            image {
              file {
                url
              }
            }
            textTitle
          }
        }
      }
		}
  `);

  const { pageTitle, elements } = data.allContentfulPaginaSoporte.nodes[0];

  return (
    <div className="t-support">
      <h1 className="t-support__title">{pageTitle}</h1>
      <div className="t-support__items">
        {elements.slice(0, 5).map((item, i) => (
          <SupportElement key={item.textTitle} {...item} main={i === 0}/>
        ))}
        {elements.slice(5).map((item) => (
          <SupportElement key={item.textTitle} {...item}/>
        ))}
      </div>
    </div>
  )
}

export default SupportTemplate;