import React from "react";
import Layout from "../components/organisms/layout/layout";
import SupportTemplate from "../components/templates/support-template/supportTemplate";

const Support = () => {
  return (
    <Layout title="Dicar | Soporte">
      <SupportTemplate />
    </Layout>
  )
}

export default Support;